import { Section, WorkContainer } from "./styles";

import allSuggested1 from "../../../../Assets/Images/Work/allSuggested1.webp";
import allSuggested2 from "../../../../Assets/Images/Work/allSuggested2.webp";
import allSuggested3 from "../../../../Assets/Images/Work/allSuggested3.webp";
import allSuggested4 from "../../../../Assets/Images/Work/allSuggested4.webp";
import allSuggested5 from "../../../../Assets/Images/Work/allSuggested5.webp";
import allSuggested6 from "../../../../Assets/Images/Work/allSuggested6.webp";
import allSuggested7 from "../../../../Assets/Images/Work/allSuggested7.webp";
import allSuggested8 from "../../../../Assets/Images/Work/allSuggested8.webp";

const suggestedWork = [
	{
		img: allSuggested1,
		title: "Xray",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Xray",
		type: "small",
	},
	{
		img: allSuggested2,
		title: "Web development",
		description: "Venhub: The future of retail",
		projectName: "Web development",
		type: "small",
	},

	{
		img: allSuggested3,
		title: "Parkin",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Parkin",
		type: "large",
	},

	{
		img: allSuggested4,
		title: "Improvis",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Improvis",
		type: "small",
	},
	{
		img: allSuggested5,
		title: "Emsisoft",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Emsisoft",
		type: "small",
	},

	{
		img: allSuggested6,
		title: "Patriot digital solutions",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Patriot digital solutions",
		type: "large",
	},

	{
		img: allSuggested7,
		title: "Neuronizers",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Neuronizers",
		type: "small",
	},
	{
		img: allSuggested8,
		title: "Fameeo",
		description: "We develop Windows, MacOS and Lin...",
		projectName: "Fameeo",
		type: "small",
	},
];

const AllSuggested = ({ handleOpenPopup }) => {
	return (
		<Section>
			{suggestedWork.map((work, index) => (
				<WorkContainer
					type={work.type}
					key={index}
					onClick={() => {
						handleOpenPopup(true, work.projectName);
					}}
				>
					<img src={work.img} alt={work.title} />
					<div>
						<span>{work.title}</span>
						<p>{work.description}</p>
					</div>
				</WorkContainer>
			))}
		</Section>
	);
};

export default AllSuggested;
