import {
	Contacts,
	ContactsContainer,
	CustomSwiperSlide,
	Section,
	SectionHeading,
} from "./styles";
import { Autoplay, FreeMode } from "swiper/modules";
import { Swiper } from "swiper/react";

const ContactsSection = () => {
	return (
		<Section>
			<SectionHeading>
				<Swiper
					loop={true}
					autoplay={{
						delay: 0,
						disableOnInteraction: false,
					}}
					effect={"fade"}
					speed={10000}
					modules={[FreeMode, Autoplay]}
					spaceBetween={40}
					slidesPerView="auto"
					navigation
					className={"eachSlide swiper-no-swiping"}
				>
					<CustomSwiperSlide black={"true"}>
						<span>Contact us</span>
					</CustomSwiperSlide>

					<CustomSwiperSlide gray={"true"}>
						<span>Contact us</span>
					</CustomSwiperSlide>
				</Swiper>
			</SectionHeading>
			<ContactsContainer>
				<Contacts>
					<h6>Visit us</h6>
					<p>
						Yeraz Residential Community 4/3 Nikoghayos Adonts Street
					</p>
					<a href="https://maps.app.goo.gl/FyNeKPVEtG27pSPB7">
						Get Directions
					</a>
				</Contacts>
				<Contacts>
					<h6>Email us</h6>
					<p>General</p>
					<a href="mailto:info@spesna.com">info@spesna.com</a>
				</Contacts>
				<Contacts>
					<h6>Call us</h6>
					<p>Phone</p>
					<a href="tel:+37477733373">+374 77 73 33 73</a>
				</Contacts>
			</ContactsContainer>
		</Section>
	);
};

export default ContactsSection;
