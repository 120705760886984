import { PopupAbout } from "./styles";
import { useMobileDetector } from "../../../../../utils/customHooks";

const About = () => {
	const ismobile = useMobileDetector();

	return (
		<PopupAbout>
			<div>
				<span>About</span>
			</div>
			<div>
				{!ismobile && <span>&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;</span>}
				<span>Venhub needed a modern website that could handle high
					traffic and complex transactions, we created a responsive
					and user-friendly site with features like secure payment
					processing, real-time inventory management, and
					easy-to-understand analytics.</span>
			</div>
		</PopupAbout>
	);
};

export default About;