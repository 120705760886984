import {
	PopupBody,
	PopupWrapper,
	CloseBtn,
	PopupContainer,
	PopupHeading,
	TextRow,
	PopupOverview,
	OverviewDescriptionContainer,
} from "./styles";
import { useEffect, useRef, useState } from "react";

import { useMobileDetector } from "../../../../utils/customHooks";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Services from "./Services";
import Outcomes from "./Outcomes";
import Pictures from "./Pictures";
import Results from "./Results";
import popup1 from "../../../../Assets/Images/Popup/popup1.webp";
import popup2 from "../../../../Assets/Images/Popup/popup2.webp";
import popup3 from "../../../../Assets/Images/Popup/popup3.webp";
import popup4 from "../../../../Assets/Images/Popup/popup4.webp";
import popup5 from "../../../../Assets/Images/Popup/popup5.webp";
import popup6 from "../../../../Assets/Images/Popup/popup6.webp";
import popup7 from "../../../../Assets/Images/Popup/popup7.webp";
import popupArrow from "../../../../Assets/Images/Home/footer-arrow.svg";
import About from "./About";

gsap.registerPlugin(ScrollTrigger);

const ProjectsPopup = ({ handleClose, info }) => {
	const ismobile = useMobileDetector();
	const textOverviewRef = useRef(null);
	const triggerOverviewRef = useRef(null);
	const scrollerRef = useRef(null);

	const [showMore, setShowMore] = useState(false);
	const [initialDescription, setInitialDescription] = useState("");
	const [remainingDescription, setRemainingDescription] = useState("");

	const textOverview =
		"Enhancing Venhub's Vending Machine Solutions with a New and Improved Web" +
		" Platform That Boosts Customer Engagement and Supports Business Growth.";

	const descriptionParagraphs =
		"Venhub required a powerful and scalable platform to enhance their operations and customer experience. Our web development team designed and implemented a sophisticated tool that supports the management of vending machines, streamlines transactions, and provides valuable insights. <br />The new platform features a responsive design, advanced inventory management, and comprehensive analytics tools, creating a seamless and efficient experience for both operators and users.";

	useEffect(() => {
		if (descriptionParagraphs) {
			const paragraphs = descriptionParagraphs.split("<br />");
			const initial = paragraphs[0];
			const remaining = paragraphs.slice(1).join("<br />");
			setInitialDescription(initial);
			setRemainingDescription(remaining);
		}
	}, [descriptionParagraphs]);

	const toggleShowMore = () => {
		setShowMore(!showMore);
	};

	useEffect(() => {

		// disabling scrolling on body doesn't work!
		document.body.style.overflow = "hidden"; // disable scrolling on the page
		document.documentElement.style.overflow = "hidden"; // disable scrolling on the page
		const enableScroll = () => {
			document.documentElement.style.overflow = "auto";
			document.body.style.overflow = "auto";
		};

		textOverviewRef.current.innerHTML = textOverview
			.split("")
			.map((char) => `<span>${char}</span>`)
			.join("");

		const lettersOverview = textOverviewRef.current.querySelectorAll("span");

		gsap.set(lettersOverview, {
			color: "#e0e0e0",
		});

		const animationOverview = gsap.to(lettersOverview, {
			scrollTrigger: {
				trigger: triggerOverviewRef.current,
				start: "top center",
				end: "bottom 50",
				scroller: scrollerRef.current,
				scrub: true,
				pin: false,
			},
			color: "#000000",
			stagger: 0.1, // Adjusted stagger for smoother transition
			duration: 1,
		});

		return () => {
			if (animationOverview.scrollTrigger) {
				animationOverview.scrollTrigger.kill();
			}
			animationOverview.kill();
			enableScroll(); // re-enable scrolling on the page
		};
	}, []);

	return (
		<PopupWrapper onClick={handleClose}>
			<PopupBody onClick={(e) => e.stopPropagation()} ref={scrollerRef}>
				<CloseBtn>
					<div onClick={handleClose}>
						<div></div>
					</div>
				</CloseBtn>
				<PopupContainer>
					<PopupHeading>
						{/*{projectInfo[info.project].heading}*/}

						<TextRow>
							<span>&emsp;&nbsp;&nbsp;&nbsp;</span>
							Venhub: the future <br />
							{ismobile && <span>&emsp;&emsp;&nbsp;</span>} of
							retail
						</TextRow>
					</PopupHeading>
					<Services />
					<PopupOverview ismobile={ismobile ? 1 : 0}>
						<div>
							<span>Overview</span>
						</div>
						<div ref={triggerOverviewRef}>
							{!ismobile && (
								<span>
									&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;
								</span>
							)}
							<span ref={textOverviewRef}></span>
						</div>
						<OverviewDescriptionContainer>
							<div>
								{ismobile ? (
									<p>
										<span
											dangerouslySetInnerHTML={{
												__html:
													initialDescription +
													(showMore
														? "<br />" +
															remainingDescription
														: ""),
											}}
										></span>
										{!showMore && remainingDescription && (
											<span onClick={toggleShowMore}>
												read more
											</span>
										)}
										{showMore && (
											<span onClick={toggleShowMore}>
												{" "}
												read less
											</span>
										)}
									</p>
								) : (
									<>
										{descriptionParagraphs
											.split("<br />")
											.map((paragraph, index) => (
												<p
													key={index}
													dangerouslySetInnerHTML={{
														__html: paragraph,
													}}
												></p>
											))}
									</>
								)}
							</div>
							<Link to="/contact-us">
								Order project <img src={popupArrow} alt="" />
							</Link>
						</OverviewDescriptionContainer>
					</PopupOverview>
					<Outcomes />
					<Pictures
						picturesObj={[
							{ type: "large", src: popup1 },
							{ type: "small", src: popup2 },
							{
								type: "small",
								src: popup3,
							},
							{ type: "large", src: popup4 },
						]}
					/>

					<About />

					<Pictures
						picturesObj={[
							{ type: "small", src: popup5 },
							{ type: "small", src: popup6 },
							{
								type: "large",
								src: popup7,
							},
						]}
					/>
					<Results popupArrow={popupArrow} />
				</PopupContainer>
			</PopupBody>
		</PopupWrapper>
	);
};

export default ProjectsPopup;
