import { OutcomesPercent, PopupOutcomes } from "./styles";

const Outcomes = () => {
	return (
		<PopupOutcomes>
			<span>outcomes in NUMBERS</span>
			<OutcomesPercent>
				<div>
					<h4>50%</h4>
					<span>increased conversions</span>
				</div>
				<div>
					<h4>35%</h4>
					<span>more booking</span>
				</div>
			</OutcomesPercent>
		</PopupOutcomes>
	);
};

export default Outcomes;
