import { PopupResults } from "./styles";
import { TextRow } from "../styles";
import { useMobileDetector } from "../../../../../utils/customHooks";
import { Link } from "react-router-dom";

const Results = ({ popupArrow }) => {
	const ismobile = useMobileDetector();

	return (
		<PopupResults>
			<div>
				<span>Results</span>
			</div>

			<div>
				<span>
					{!ismobile && (
						<span>&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;</span>
					)}
					The newly developed platform made a big difference for
					Venhub, leading to increased sales and better customer
					engagement. Additionally, the platform supported Venhub’s
					growth and expansion, helping them strengthen their position
					as a leader in the vending machine tool industry.
				</span>

				<div>
					<Link to="/contact-us">
						Let’s talk and discuss your project{" "}
						<img src={popupArrow} alt="" />
					</Link>
				</div>
			</div>
		</PopupResults>
	);
};

export default Results;
