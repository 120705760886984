import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	left: 0;
	right: 0;
	max-width: 1400px;
	margin: 140px auto 0 auto;
    display: flex;
    flex-direction: column;
    gap: 80px;
	
	@media (max-width: 768px) {
        margin-top: 60px;
		gap: 28px;
	}
`;

export const SectionHeading = styled.h1`
	display: flex;
	flex-direction: column;

    @media (max-width: 768px) {
		align-items: center;
    }
	
	@media (min-width: 1600px) {
        span:first-child {
            margin-left: 89px;
        }
    }
`;

export const TextRow = styled.span`
    font-size: calc(24px + 4.5vw);
    font-weight: 500;
    line-height: calc(29px + 5.438vw);
    text-align: left;
    text-transform: uppercase;
	
	&:first-child {
        margin-left: 5.563vw;
	}

    @media (max-width: 1200px) {
        font-size: calc(12px + 4.5vw);
        line-height: calc(14.5px + 5.438vw);
    }

    @media (min-width: 768px) and (max-width: 992px) {
        &:first-child {
            margin-left: 1.863vw;
        }
    }

    @media (max-width: 768px) {
        &:first-child {
            margin: 0 calc(60px + 18.229vw) 0 0;
        }
        &:nth-child(2) {
            margin: calc(-3px - 0.911vw) 0 0 calc(18px + 5.469vw);
        }
        &:nth-child(3) {
            margin: calc(-3px - 0.911vw) calc(9.6px + 2.917vw) 0 0;
        }
	}
	
	@media (min-width: 1600px) {
        font-size: 96px;
        line-height: 116.18px;
		
		&:first-child {
			margin-left: 89px;
		}
    }
`

export const StartupWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: calc(40px + 2.5vw);
    border-radius: 30px;
	background-color: #1C1B1F;
	padding: calc(30px + 1.875vw) 0 calc(38px + 2.375vw) 0;
	
	@media (min-width: 1600px) {
        gap: 80px;
        padding: 60px 0 76px 0;
    }
`

export const ContactUs = styled.div`
	max-width: 867px;
	padding-inline: calc(20px + 2.875vw);
	
	p {
        font-size: calc(10px + 0.625vw);
        font-weight: 400;
        line-height: calc(12px + 0.762vw);
        letter-spacing: 0.02em;
        text-align: left;
		color: #FFFFFF;
		margin: 0;
    }
	
	div {
        display: flex;

        a {
            padding: 16px 40px;
            border-radius: 90px;
            background-color: #FFFFFF;
            font-size: calc(14px + 0.875vw);
            font-weight: 500;
            line-height: calc(17px + 1.056vw);
            letter-spacing: 0.02em;
            text-align: left;
            color: #121212;
            margin-top: calc(23px + 1.438vw);
            display: flex;
			align-items: center;
            gap: calc(16px + 1vw);
            text-decoration: none;

            img {
                max-width: calc(16px + 1vw);
                max-height: calc(16px + 1vw);
            }
        }
	}
	
	@media (max-width: 480px) {
        div {
            width: 100%;
			
			a {
                width: 100%;
				justify-content: center;
                gap: 4px;
			}
        }
	}

    @media (min-width: 1600px) {
        padding-inline: 66px;

        p {
            font-size: 20px;
            line-height: 24.2px;
        }

		div {
            a {
                padding: 16px 40px;
                font-size: 28px;
                line-height: 33.89px;
                margin-top: 46px;
                gap: 32px;

                img {
                    max-width: 32px;
                    max-height: 32px;
                }
            }
		}
        
    }
`

export const StartupRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(10px + 0.625vw);
	
	@media (min-width: 1600px) {
		gap: 20px;
    }
`

export const StartupContainer = styled.div`
	width: 100%;
	display: flex;

    & > div > div {
		transition-timing-function: linear !important;
    }
`

export const StartupImage = styled.div`
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }
`