import { Picture, PopupPictures } from "./styles";

const Pictures = ({ picturesObj }) => {
	return (
		<PopupPictures>
			{picturesObj.map((picture, index) => (
				<Picture key={index} type={picture.type}>
					<img src={picture.src} alt="" />
				</Picture>
			))}
		</PopupPictures>
	);
};

export default Pictures;
