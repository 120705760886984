import {
	ContactUs,
	Section,
	SectionHeading,
	StartupContainer,
	StartupImage,
	StartupRow,
	StartupWrapper,
	TextRow,
} from "./styles";
import ContactUsArrow from "../../../../Assets/Images/Culture/contact-us-arrow.svg";
import servingStartupImg1 from "../../../../Assets/Images/Culture/servingStartups1.avif";
import servingStartupImg2 from "../../../../Assets/Images/Culture/servingStartups2.avif";
import servingStartupImg3 from "../../../../Assets/Images/Culture/servingStartups3.avif";
import servingStartupImg4 from "../../../../Assets/Images/Culture/servingStartups4.avif";
import servingStartupImg5 from "../../../../Assets/Images/Culture/servingStartups5.avif";
import servingStartupImg6 from "../../../../Assets/Images/Culture/servingStartups6.avif";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";
// import { CustomSwiperSlide, ReputationImage } from "../../Home/ReputationSwiper/styles";

// Import Swiper styles
import "swiper/css";
import { Link } from "react-router-dom";
import { useMobileDetector } from "../../../../utils/customHooks";

const ServingStartups = () => {
	const ismobile = useMobileDetector();

	return (
		<Section>
			<SectionHeading>
				{ismobile ? (<>
					<TextRow>Serving</TextRow>
					<TextRow>Startups to</TextRow>
					<TextRow>Global Giants</TextRow>
				</>) : (<>
					<TextRow>Serving Startups to</TextRow>
					<TextRow>Global Giants</TextRow>
				</>)}

			</SectionHeading>

			<StartupWrapper>
				<ContactUs>
					<p>
						Spesnas Satisfied Clients: A Global Network of Success{" "}
						<br /> From dynamic startups reshaping industries to
						established global organizations leading their
						respective fields, our client base spans across diverse
						sectors and continents.
					</p>

					<div>
						<Link to="/contact-us">
							Contact us <img src={ContactUsArrow} alt="" />
						</Link>
					</div>
				</ContactUs>

				<StartupRow>
					<StartupContainer>
						<Swiper
							loop={true}
							autoplay={{
								delay: 0,
								disableOnInteraction: false,
								reverseDirection: true,
							}}
							effect={"fade"}
							speed={5000}
							modules={[FreeMode, Autoplay]}
							spaceBetween={20}
							slidesPerView={5}
							navigation
							className={"eachSlide swiper-no-swiping"}
							breakpoints={{
								0: {
									slidesPerView: 2,
								},
								480: {
									slidesPerView: 3,
								},
								992: {
									slidesPerView: 4,
								},
								1200: {
									slidesPerView: 5,
								},
							}}
						>
							<SwiperSlide>
								<StartupImage>
									<img src={servingStartupImg1} alt="" />
								</StartupImage>
							</SwiperSlide>
							<SwiperSlide>
								<StartupImage>
									<img src={servingStartupImg2} alt="" />
								</StartupImage>
							</SwiperSlide>
							<SwiperSlide>
								<StartupImage>
									<img src={servingStartupImg3} alt="" />
								</StartupImage>
							</SwiperSlide>
							<SwiperSlide>
								<StartupImage>
									<img src={servingStartupImg4} alt="" />
								</StartupImage>
							</SwiperSlide>
							<SwiperSlide>
								<StartupImage>
									<img src={servingStartupImg5} alt="" />
								</StartupImage>
							</SwiperSlide>
							<SwiperSlide>
								<StartupImage>
									<img src={servingStartupImg6} alt="" />
								</StartupImage>
							</SwiperSlide>
						</Swiper>
					</StartupContainer>

					<StartupContainer>
						<Swiper
							loop={true}
							autoplay={{
								delay: 0,
								disableOnInteraction: false,
							}}
							effect={"fade"}
							speed={5000}
							modules={[FreeMode, Autoplay]}
							spaceBetween={20}
							slidesPerView={5}
							navigation
							className={"eachSlide swiper-no-swiping"}
							breakpoints={{
								0: {
									slidesPerView: 2,
								},
								480: {
									slidesPerView: 3,
								},
								992: {
									slidesPerView: 4,
								},
								1200: {
									slidesPerView: 5,
								},
							}}
						>
							<SwiperSlide>
								<StartupImage>
									<img src={servingStartupImg1} alt="" />
								</StartupImage>
							</SwiperSlide>
							<SwiperSlide>
								<StartupImage>
									<img src={servingStartupImg2} alt="" />
								</StartupImage>
							</SwiperSlide>
							<SwiperSlide>
								<StartupImage>
									<img src={servingStartupImg3} alt="" />
								</StartupImage>
							</SwiperSlide>
							<SwiperSlide>
								<StartupImage>
									<img src={servingStartupImg4} alt="" />
								</StartupImage>
							</SwiperSlide>
							<SwiperSlide>
								<StartupImage>
									<img src={servingStartupImg5} alt="" />
								</StartupImage>
							</SwiperSlide>
							<SwiperSlide>
								<StartupImage>
									<img src={servingStartupImg6} alt="" />
								</StartupImage>
							</SwiperSlide>
						</Swiper>
					</StartupContainer>
				</StartupRow>
			</StartupWrapper>
		</Section>
	);
};

export default ServingStartups;
