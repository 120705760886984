import { useRef, useState } from "react";
import { useMobileDetector, useSendEmail } from "../../../../utils/customHooks";

import {
	Section,
	SectionHeading,
	TextRow,
	ShareWrapper,
	Input,
	NotificationBar,
	NotificationContainer,
} from "./styles";
import successIcon from "../../../../Assets/Images/NotificationBar/success.webp";
import warningIcon from "../../../../Assets/Images/NotificationBar/warning.webp";

const ShareVision = ({ id }) => {
	const ismobile = useMobileDetector();
	const [formData, setFormData] = useState({
		fullName: "",
		email: "",
		tel: "",
	});
	const { sendEmail, validationErrors, setValidationErrors, formRefs } =
		useSendEmail("https://server.spesna.com/api/company/submit");
	const [notification, setNotification] = useState({
		message: "",
		type: "",
		visible: false,
	});
	const formRef = useRef(null);

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));

		// Clear the error for the specific field being modified
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			[name]: "",
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		const response = await sendEmail(formData);

		if (response) {
			if (response === "SUCCESS") {
				setNotification({
					message: "Email sent successfully!",
					type: "success",
					visible: true,
				});
				setFormData({
					fullName: "",
					email: "",
					tel: "",
					company: "",
					siteURL: "",
					message: "",
				});
			} else {
				setNotification({
					message: "Error sending email.",
					type: "warning",
					visible: true,
				});
			}
		} else {
			window.scrollTo({
				top: formRef.current.offsetTop - 130,
				behavior: "smooth",
			});
		}

		setTimeout(() => {
			setNotification((prev) => ({ ...prev, visible: false }));
		}, 2000);
	};

	return (
		<Section id={id}>
			{notification.visible && (
				<NotificationBar>
					{notification.type === "success" ? (
						<NotificationContainer success={1}>
							<img src={successIcon} alt="icon" />
							<span>{notification.message}</span>
						</NotificationContainer>
					) : (
						<NotificationContainer success={0}>
							<img src={warningIcon} alt="icon" />
							<span>{notification.message}</span>
						</NotificationContainer>
					)}
				</NotificationBar>
			)}
			<SectionHeading>
				{ismobile ? (
					<>
						<TextRow>Share your</TextRow>
						<TextRow>vision</TextRow>
						<TextRow>we'll plan</TextRow>
						<TextRow>the next step</TextRow>
						<TextRow>together</TextRow>
					</>
				) : (
					<>
						<TextRow>Share your vision</TextRow>
						<TextRow>we'll plan</TextRow>
						<TextRow>the next step together</TextRow>
					</>
				)}
			</SectionHeading>

			<ShareWrapper>
				<form ref={formRef} onSubmit={handleSubmit}>
					<div>
						<Input
							type="text"
							placeholder="Name*"
							name="fullName"
							ref={formRefs.fullName}
							onChange={handleChange}
							error={validationErrors.fullName}
						/>
						{validationErrors.fullName && (
							<p>{validationErrors.fullName}</p>
						)}
					</div>
					<div>
						<Input
							type="text"
							placeholder="Email*"
							name="email"
							ref={formRefs.email}
							onChange={handleChange}
							error={validationErrors.email}
						/>
						{validationErrors.email && (
							<p>{validationErrors.email}</p>
						)}
					</div>
					<div>
						<Input
							type="text"
							placeholder="Phone Number"
							name="tel"
							ref={formRefs.tel}
							onChange={handleChange}
						/>
					</div>
					<button type="submit">Send</button>
				</form>
			</ShareWrapper>
		</Section>
	);
};

export default ShareVision;
