import {
	Navbar,
	NavLinkMobile,
	NavLinkContainer,
	NavLink,
	ContactLink,
	Logo,
} from "./styles";
// import logoImgHome from "../../../Assets/Images/logo.svg";
// import logoImgOther from "../../../Assets/Images/logo2.svg";
import logoWhite from "../../../Assets/Images/logo-white.svg";
import logoBlack from "../../../Assets/Images/logo-black.svg";

import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMobileDetector } from "../../../utils/customHooks";

const DefaultNavbar = ({ handleOpenMenu }) => {
	let location = useLocation();
	const ismobile = useMobileDetector();
	const [navbarVisible, setNavbarVisible] = useState(true);
	const [lastScrollTop, setLastScrollTop] = useState(0); // To track last scroll position
	const attop = window.scrollY < 100;

	useEffect(() => {
		const handleScroll = () => {
			const currentScroll = window.scrollY;
			const viewportHeight = window.innerHeight;

			// Determine if the user is scrolling down or up
			if (
				currentScroll > lastScrollTop &&
				currentScroll > viewportHeight
			) {
				// Scrolling down, and past 100vh, hide the navbar
				// setScrollingDown(true);
				setNavbarVisible(false);
			} else if (currentScroll < lastScrollTop) {
				// Scrolling up, show the navbar again
				// setScrollingDown(false);
				setNavbarVisible(true);
			}

			// Update last scroll position
			setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
		};

		// Add scroll event listener
		window.addEventListener("scroll", handleScroll);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [lastScrollTop]);

	return (
		<Navbar
			loc={location.pathname}
			visible={navbarVisible ? 1 : 0}
			attop={attop ? 1 : 0}
			ismobile={ismobile ? 1 : 0}
		>
			<div>
				<div>
					<NavLinkMobile
						loc={location.pathname}
						onClick={handleOpenMenu}
						attop={attop ? 1 : 0}
					>
						<div></div>
					</NavLinkMobile>

					<NavLink to={"/"}>
						<Logo loc={location.pathname} attop={attop ? 1 : 0}
							src={
								location.pathname === "/"
									? (attop ? logoWhite : logoBlack)
									: logoBlack
							}
							alt={"Spesna"}
						/>
					</NavLink>
				</div>

				<NavLinkContainer>
					<NavLink
						to={"/services"}
						loc={location.pathname}
						current={
							(location.pathname === "/services" && "true") || ""
						}
						attop={attop ? 1 : 0}
					>
						Services
					</NavLink>
					<NavLink
						to={"/work"}
						loc={location.pathname}
						current={
							(location.pathname === "/work" && "true") || ""
						}
						attop={attop ? 1 : 0}
					>
						Work
					</NavLink>
					<NavLink
						to={"/culture"}
						loc={location.pathname}
						current={
							(location.pathname === "/culture" && "true") || ""
						}
						attop={attop ? 1 : 0}
					>
						Culture
					</NavLink>
					<NavLink
						to={"/contact-us"}
						loc={location.pathname}
						current={
							(location.pathname === "/contact-us" && "true") ||
							""
						}
						attop={attop ? 1 : 0}
					>
						Contact
					</NavLink>
				</NavLinkContainer>

				<ContactLink
					to={"/contact-us"}
					loc={location.pathname}
					current={
						(location.pathname === "/contact-us" && "true") || ""
					}
					attop={attop ? 1 : 0}
				>
					Let’s Talk
				</ContactLink>
			</div>
		</Navbar>
	);
};

export default DefaultNavbar;
