import {
	FooterSection,
	CompanyInfo,
	TextRow,
	PrivacyPolicy,
	BusinessIdea,
	SocialLinks,
	Copyright,
	PageLinks,
	TextRowLink,
} from "./styles";
import logoSmallImg from "../../../Assets/Images/logoSmall.webp";
import footerArrow from "../../../Assets/Images/Home/footer-arrow.svg";
import { useMobileDetector } from "../../../utils/customHooks";

const DefaultFooter = () => {
	const ismobile = useMobileDetector();

	return (
		<FooterSection>
			<div>
				<div>
					<CompanyInfo>
						<img src={logoSmallImg} alt="" />
						{!ismobile && (
							<div>
								<TextRow>Yeraz Residential Community</TextRow>
								<TextRow>4/3 Nikoghayos Adonts Street</TextRow>
								<span>
									<TextRowLink to={""}>
										info@spesna.com{" "}
										<img src={footerArrow} alt={""} />
									</TextRowLink>
								</span>
							</div>
						)}
					</CompanyInfo>
				</div>
				<div>
					<BusinessIdea>
						<div>
							<TextRow>Have a great business idea?</TextRow>
						</div>
						<div>
							<TextRowLink to={"/contact-us"}>
								LETS TALK <img src={footerArrow} alt={""} />
							</TextRowLink>
						</div>
					</BusinessIdea>
					<PageLinks>
						{ismobile ? (
							<>
								<div>
									<span>
										<TextRowLink to={"/"}>Home</TextRowLink>
									</span>
									<span>
										<TextRowLink to={"/services"}>
											Services
										</TextRowLink>
									</span>
									<span>
										<TextRowLink to={"/work"}>
											Work
										</TextRowLink>
									</span>
									<span>
										<TextRowLink to={"/culture"}>
											Culture
										</TextRowLink>
									</span>
								</div>
								<div>
									<span>
										<TextRowLink to={"/contact-us"}>
											Contact
										</TextRowLink>
									</span>
									<span>
										<TextRowLink to={""}>
											Careers
										</TextRowLink>
									</span>
									<PrivacyPolicy>
										<span>
											<TextRowLink to={""}>
												Privacy Policy
											</TextRowLink>
										</span>
										<span>
											<TextRowLink to={""}>
												Terms of Services
											</TextRowLink>
										</span>
									</PrivacyPolicy>
								</div>
							</>
						) : (
							<>
								<span>
									<TextRowLink to={"/services"}>
										Services
									</TextRowLink>
								</span>
								<span>
									<TextRowLink to={"/work"}>Work</TextRowLink>
								</span>
								<span>
									<TextRowLink to={"/culture"}>
										Culture
									</TextRowLink>
								</span>
								<span>
									<TextRowLink to={"/contact-us"}>
										Contact
									</TextRowLink>
								</span>
								<span>
									<TextRowLink to={""}>Careers</TextRowLink>
								</span>
							</>
						)}
					</PageLinks>
					{!ismobile ? (
						<SocialLinks>
							<TextRowLink to={""}>LinkedIn</TextRowLink>
							<TextRowLink to={""}>Behance</TextRowLink>
							<TextRowLink to={""}>Instagram</TextRowLink>
						</SocialLinks>
					) : (
						<CompanyInfo>
							<div>
								<TextRow>Get in touch</TextRow>
								<TextRow>Yeraz Residential Community</TextRow>
								<TextRow>4/3 Nikoghayos Adonts Street</TextRow>
								<span>
									<TextRowLink to={""}>
										info@spesna.com{" "}
										<img src={footerArrow} alt={""} />
									</TextRowLink>
								</span>
							</div>
						</CompanyInfo>
					)}
				</div>
				<div>
					{!ismobile ? (
						<PrivacyPolicy>
							<TextRowLink to={""}>Privacy Policy</TextRowLink>
							<TextRowLink to={""}>Terms of Services</TextRowLink>
						</PrivacyPolicy>
					) : (
						<SocialLinks>
							<TextRowLink to={""}>LinkedIn</TextRowLink>
							<TextRowLink to={""}>Behance</TextRowLink>
							<TextRowLink to={""}>Instagram</TextRowLink>
						</SocialLinks>
					)}
				</div>
				<div>
					<Copyright>
						<TextRow>© 2024 Spesna. All rights reserved</TextRow>
					</Copyright>
				</div>
			</div>
		</FooterSection>
	);
};

export default DefaultFooter;
