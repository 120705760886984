import { PopupServices } from "./styles";

const Services = () => {
	return (
		<PopupServices>
			<div>
				<span>Client</span>
				<p>Venhub</p>
			</div>
			<div>
				<span>Services</span>
				<p>
					Web Development
					<br />
					UX/UI Design
					<br />
					Graphic Design
				</p>
			</div>
			<div>
				<span>Sector</span>
				<p>
					Vending Machines
					<br />
					AI
				</p>
			</div>
		</PopupServices>
	);
};

export default Services;
