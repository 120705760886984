import {
	Section,
	SectionHeading,
	TextRow,
	QuestionsWrapper,
	QuestionContainer,
	QuestionTitle,
} from "./styles";
import { useMobileDetector } from "../../../../utils/customHooks";

const questions = [
	{
		title: "What services does Spesna Team offer?",
		content:
			"We specialize in UX/UI design, web and mobile development, QA engineering and testing, SEO, " +
			"and marketing. Our diverse team can handle a wide range of projects to meet your digital needs.",
	},
	{
		title: "What makes Spesna Team's UX/UI design unique?",
		content:
			"We specialize in UX/UI design, web and mobile development, QA engineering and testing, SEO, " +
			"and marketing. Our diverse team can handle a wide range of projects to meet your digital needs.",
	},
	{
		title: "How does Spesna Team approach web and mobile development?",
		content:
			"We specialize in UX/UI design, web and mobile development, QA engineering and testing, SEO, " +
			"and marketing. Our diverse team can handle a wide range of projects to meet your digital needs.",
	},
	{
		title: "What is included in our QA engineering and testing services?",
		content:
			"We specialize in UX/UI design, web and mobile development, QA engineering and testing, SEO, " +
			"and marketing. Our diverse team can handle a wide range of projects to meet your digital needs.",
	},
];

const Questions = () => {
	const ismobile = useMobileDetector();

	return (
		<Section>
			<SectionHeading>
				{ismobile ? (<>
					<TextRow>You have</TextRow>
					<TextRow>questions</TextRow>
					<TextRow>We have</TextRow>
					<TextRow>answers</TextRow>
				</>) : (<>
					<TextRow>You have</TextRow>
					<TextRow>questions</TextRow>
					<TextRow>We have answers</TextRow>
				</>)}

			</SectionHeading>

			<QuestionsWrapper>
				{questions.map((question, index) => (
					<QuestionContainer key={index} open={index === 0}>
						<QuestionTitle>{question.title}</QuestionTitle>
						<p>{question.content}</p>
					</QuestionContainer>
				))}
			</QuestionsWrapper>
		</Section>
	);
};

export default Questions;
