import styled, { keyframes } from "styled-components";
import { SwiperSlide } from "swiper/react";

export const Section = styled.section`
	flex: 1;
	min-height: 600px;
	max-height: 1100px;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 30px;
	align-content: space-between;
	box-sizing: border-box;
	max-width: 1400px;
    margin: 0 auto;
    position: relative;
    bottom: env(safe-area-inset-bottom);
    top: 0;
`;

export const SectionHeading = styled.h1`
    position: relative;
	font-size: calc(24px + 4.5vw);
	font-weight: 500;
	line-height: calc(32px + 5.25vw);
	text-align: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	text-transform: uppercase;
	white-space: nowrap;
	margin: 187px auto 0;
	width: 100%;
	
	@media (min-width: 320px) and (max-width: 768px) {
		font-size: calc(12px + 6.25vw);
		line-height: calc(16px + 7.031vw);
	}

	@media (max-width: 320px) {
		font-size: calc(8px + 7.5vw);
		line-height: calc(12px + 8.353vw);
		text-align: left;
		white-space: nowrap;
	}

	@media (min-width: 1600px) {
		font-size: 96px;
		line-height: 116.18px;
	}
`;

export const FirstTextRow = styled.div`
	overflow: hidden;
	
	& > div > span {
		animation-delay: 0s;
    }
	
	& > div {
        overflow: hidden;
	}
	
	@media (max-width: 768px) {
        & > div:first-child > span {
            animation-delay: 0s;
        }
        & > div:nth-child(2) > span {
            animation-delay: 0.25s;
        }
    }

    @media (min-width: 320px) and (max-width: 768px) {
        display: flex;
        justify-content: center;
		flex-direction: column;
    }

    @media (max-width: 320px) {
        & > span:nth-child(2) {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: calc(-3px - 0.188vw) 0 0 0;
        }
    }
`

export const SecondTextRow = styled.div`
    overflow-x: hidden !important;

    & > div {
        overflow: hidden !important; // check on safari
        //overflow-x: hidden !important;
    }

    & > div > span {
        animation-delay: 0.5s;
		position: relative;
		z-index: 1;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        & > span {
            margin-left: 0;
        }
    }

    @media (min-width: 320px) and (max-width: 768px) {
        width: 100%;
		
        & > span {
            width: 100%;
        }
    }

    @media (max-width: 320px) {
        & > span:first-child {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin: calc(-3px - 0.188vw) 0 0 0;
        }
    }
`

export const ThirdTextRow = styled.div`
    overflow: hidden;

    & > div {
		overflow: hidden;
	}

    & > div > span {
		animation-delay: 1s;
    }

    @media (max-width: 768px) {
        & > div:first-child > span {
            animation-delay: 0.75s;
        }
        & > div:nth-child(2) > span {
            animation-delay: 1s;
        }
    }

    @media (min-width: 768px) and (max-width: 992px) {
        & > span {
            width: 100%;
            margin: -8px 0 0 0;
            display: flex;
            justify-content: flex-start;
        }
    }

    @media (min-width: 320px) and (max-width: 768px) {
        width: 100%;

        & > div:first-child > span {
            width: 100%;
            display: flex;
			justify-content: center;
            margin: calc(-4px - 0.25vw) 0 0 0;
        }
		
        & > div:nth-child(2) > span {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: calc(-4px - 0.25vw) 0 0 0;
        }

    }

    @media (max-width: 320px) {
        & > span:nth-child(5) {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin: calc(-3px - 0.188vw) 0 0 0;
        }

        & > span:nth-child(4) {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin: calc(-3px - 0.188vw) 0 0 0;
        }
    }
`

const RevealText = keyframes`
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
`

export const TextRow = styled.span`
	display: block;
    transform: translate(0, 100%);
    animation: ${RevealText} 1.5s cubic-bezier(0.77, 0, 0.175, 1) 0.5s;
    animation-fill-mode: forwards !important;
`;

const RevealShape = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`

const BounceShape = (size) => keyframes`
	0% {
		transform: translateY(${size});
	}
	50% {
		transform: translateY(0);
	}
	100% {
        transform: translateY(${size});
	}
`

export const RoundedShape = styled.img`
	position: absolute;
	width: calc(113px + 50vw);
	height: calc(113px + 50vw);
	opacity: 0;
    animation-delay: 2s;
    animation: ${RevealShape} 2s cubic-bezier(0.77, 0, 0.175, 1) 0.5s, ${BounceShape('calc(-5px - 1.563vw)')} 5s linear infinite;
    animation-fill-mode: forwards;
    margin: calc(-100px - 18.75vw) 0 0 calc(-75px - 4.688vw);
	z-index: 0;
	
	@media (min-width: 480px) and (max-width: 768px) {
        width: calc(313px + 50vw);
        height: calc(313px + 50vw);
        margin: calc(-150px - 20vw) 0 0 calc(-220px - 7.5vw);
	}

    @media (max-width: 480px) {
        width: calc(280px + 50vw);
        height: calc(280px + 50vw);
        margin: calc(-150px - 20vw) 0 0 calc(-210px - 7.5vw);
        z-index: 1;
    }

	@media (min-width: 1600px) {
		width: 913.88px;
		height: 913.88px;
        margin: -400px 0 0 -150px;
        animation: ${RevealShape} 2s cubic-bezier(0.77, 0, 0.175, 1) 0.5s, ${BounceShape('-30px')} 2s linear infinite;
        animation-fill-mode: forwards;
	}
`;

const TextAppear = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`

export const ShippedContainer = styled.div`
	position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    opacity: 0;
    animation: ${TextAppear} 2s cubic-bezier(0.77, 0, 0.175, 1) 0.5s;
    animation-fill-mode: forwards;
	min-height: 113px;

    & > img {
        width: 100%;
    }

    & > span {
        font-family: Inter, sans-serif;
        font-size: calc(10px + 0.625vw);
        font-weight: 500;
        line-height: calc(12px + 0.762vw);
        letter-spacing: 0.02em;
        text-align: center;
        color: #000;
        display: flex;
        gap: 12px;
        margin-bottom: calc(-10px - 2.5vw);
        width: 100%;
        justify-content: center;
		align-items: center;
		
		& > img {
			width: calc(12px + 0.75vw);
            height: calc(12px + 0.75vw);
            transform: rotate(90deg);
		}
    }
	
	@media (max-width: 768px) {
        min-height: 49px;
		margin-bottom: 100px;
	}

    @media (min-width: 1600px) {
        span {
            font-size: 20px;
            line-height: 24.2px;
            gap: 12px;
			
			& > img {
				width: 24px;
				height: 24px;
			}
        }

        span > img {
            width: 24px;
            height: 24px;
        }
    }
`;

export const ShippedProducts = styled.div`
    width: 100%;
	display: flex;
	position: relative;
	
	&:after {
		content: '';
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
                to right,
                rgba(255, 255, 255, 1),
                transparent 50%,
                transparent 50%,
                rgba(255, 255, 255, 1)
        );
        z-index: 3;
        pointer-events: none;
	}
    

    & > div > div {
        transition-timing-function: linear !important;
		width: 100%;
    }
`

export const CustomSwiperSlide = styled(SwiperSlide)`
	display: flex;
	width: auto !important;
	height: 100% !important;
	
	span {
        font-size: calc(12px + 1vw);
        font-weight: 500;
        line-height: calc(16px + 1.046vw);
        text-align: left;
	}
	
	@media (min-width: 1600px) {
		span {
            font-size: 28px;
            line-height: 38.73px;
		}
	}
`

export const HeroButton = styled("button")`
	cursor: pointer;
	margin-top: 3.75vw;
	padding: 16px 28px;
	border-radius: 90px;
	background: #000000;
	font-size: calc(10px + 1.125vw);
	font-weight: 500;
	line-height: calc(10px + 1.493vw);
	letter-spacing: 0.02em;
	color: #ffffff;
	z-index: 3;
	border: none;

	@media (max-width: 480px) {
		margin-top: 60px;
		width: 100%;
	}

	@media (min-width: 1600px) {
		font-size: 28px;
		line-height: 33.89px;
		margin-top: 80px;
	}
`;

export const TrustedIcons = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;

	span {
		font-size: calc(10px + 0.625vw);
		font-weight: 400;
		line-height: 24.2px;
		letter-spacing: 0.02em;
		text-align: left;
	}

	@media (min-width: 1600px) {
		gap: 5px;

		span {
			font-size: 20px;
			line-height: 24.2px;
		}
	}
`;
