import styled from "styled-components";
import popupCloseIconWhite from "../../../../Assets/Images/Popup/popup-close-white.svg";
import popupCloseIconBlack from "../../../../Assets/Images/Popup/popup-close-black.svg";

export const PopupWrapper = styled.div`
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	background: rgba(35, 35, 35, 0.5); /* Semi-transparent background */
	backdrop-filter: blur(10px); /* Apply blur effect */
`;

export const PopupBody = styled.div`
	box-sizing: border-box;
	z-index: 1111;
	background-color: #ffffff;
	position: absolute;
	width: 100%;
	height: 90%;
	border-radius: 90px 90px 0 0;
	overflow-y: auto; /* Enable vertical scrolling */
	overflow-x: hidden; /* Prevent horizontal scrolling */
	max-height: 90%;
    padding: 118px 100px 240px 100px;

	&::-webkit-scrollbar {
		width: 0;
	}

	@media (min-width: 768px) and (max-width: 1200px) {
		padding: 118px 70px 240px 70px;
	}

	@media (min-width: 480px) and (max-width: 768px) {
		border-radius: 60px 60px 0 0;
        padding: 118px 40px 240px 40px;
	}

    @media (max-width: 480px) {
        border-radius: 30px 30px 0 0;
		padding: 118px 15px 118px 15px;
    }
`;

export const CloseBtn = styled.button`
	position: fixed;
	border: none;
	top: 15%;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 100vw;
	background-color: transparent;
	border-radius: 90px 90px 0 0;
	height: 96px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
    z-index: 99;
	
	& > div {
		width: 52px;
		height: 32px;
        margin-right: 28px;
        cursor: pointer;
        padding: 3px 13px;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
        background-color: #FAFAFA;
        border-radius: 90px;
        transition: 0.3s ease;
		
		& > div {
			width: 100%;
			height: 100%;
            background-image: url(${popupCloseIconBlack});
            background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;
            transition: 0.3s ease;
		}
		
        &:hover {
            background-color: #222222;
			
			& > div {
                background-image: url(${popupCloseIconWhite});
			}
        }
	}

    @media (min-width: 480px) and (max-width: 768px) {
        border-radius: 60px 60px 0 0;
        top: 10%;
		
		& > div {
            margin-right: 16px;
		}
    }

    @media (max-width: 480px) {
        border-radius: 30px 30px 0 0;
        top: 10%;

        & > div {
            width: 38px;
            height: 22.5px;
            padding: 4.27px 12px;
            margin-right: 16px;
        }
    }

	@media (min-width: 1600px) {
		img {
            margin-right: 28px;
		}
	}
`;

export const PopupContainer = styled.div`
	position: relative;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 100%;
	overflow: hidden;
`;

export const PopupHeading = styled.h1`
	display: flex;
	flex-direction: column;

	h1 {
		text-transform: uppercase;
	}

	h1:first-child {
		margin-left: 80px;
	}

	h1:nth-child(2) {
		margin-top: -80px;
	}

	@media (max-width: 1400px) {
		h1:first-child {
        margin-left: 0;
    }
		h1:nth-child(2) {
			margin-top: -40px;
		}
	}
`;

export const TextRow = styled.span`
	font-family: Inter, sans-serif;
	font-size: calc(24px + 4.5vw);
	font-weight: 500;
	line-height: calc(26px + 5.01vw);
	text-align: left;
	text-transform: uppercase;
	
	@media (max-width: 768px) {
		&:first-child {
			margin-left: 0;
		}
		&:nth-child(2) {
			margin-left: calc(-5px - 5.208vw);
		}
        &:nth-child(3) {
            margin-left: calc(10px + 16.927vw);
        }
	}

	@media (min-width: 1600px) {
		font-size: 96px;
		line-height: 116.18px;
	}
`;

export const PopupOverview = styled.div`
    width: 100%;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
    position: relative;

    & > div:first-child {
        position: ${
	(props) => (props.ismobile ? "relative" : "absolute")
};
        top: 0;
        left: 0;
		display: flex;
		width: 100%;
		justify-content: flex-start;

        span {
            font-family: Inter, sans-serif;
            font-size: calc(10px + 0.625vw);
            font-weight: 400;
            line-height: calc(12px + 0.762vw);
            text-align: left;
            text-transform: uppercase;
            display: flex;
            gap: calc(10px + 0.625vw);
        }

        span::before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            background-color: rgba(0, 0, 0, 1);
            border-radius: 50%;
        }
    }

    & > div:nth-child(2) {
        span {
            font-family: Inter, sans-serif;
            font-size: calc(24px + 2.375vw);
            font-weight: 500;
            line-height: calc(32px + 2.689vw);
            text-align: left;
            margin-top: calc(-12px - 0.875vw);
        }

        span:nth-child(2) {
            margin-top: ${
	(props) => (!props.ismobile && "-40px")
};
			
        }
    }

    @media (max-width: 992px) {
        & > div:nth-child(2) > span {
            margin-top: 0;
        }
    }

    @media (max-width: 480px) {
        & > div:nth-child(2) > span {
            margin-right: 0 !important;
        }
    }

    @media (max-width: 320px) {
        margin-top: 40px;

        & > div:nth-child(2) > span {
            font-size: 24px;
            line-height: 29.05px;
        }
    }

    @media (min-width: 1600px) {
        span {
            font-size: 20px;
            line-height: 24.2px;
            gap: 20px;
        }

        & > div:nth-child(2) {
            span {
                font-size: 62px;
                line-height: 75.03px;

            }
        }
    }
`;

export const OverviewDescriptionContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	width: 61.9285%;
	gap: calc(12px + 2.125vw);
	margin-right: 66px;
	margin-top: 40px;

	div {
		display: flex;
        gap: 67px;

        p {
            font-family: Inter, sans-serif;
            font-size: calc(10px + 0.625vw);
            font-weight: 400;
            line-height: calc(12px + 0.762vw);
            letter-spacing: 0.02em;
            text-align: left;
            color: rgba(0, 0, 0, 1);
			
            &:first-child {
                width: 46.1361%;
            }

            &:nth-child(2) {
                width: 52.8639%;
            }

            span:nth-child(2) {
                font-size: 14px;
                font-weight: 700;
                line-height: 16.94px;
                letter-spacing: 0.02em;
                text-align: left;
                text-decoration-line: underline;
                text-decoration-style: solid;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                cursor: pointer;
            }
        }
	}

	a {
		width: 46.1361%;
		background-color: rgba(0, 0, 0, 1);
		color: rgba(255, 255, 255, 1);
		font-family: Inter, sans-serif;
		font-size: calc(14px + 0.875vw);
		font-weight: 500;
		line-height: calc(20px + 0.868vw);
		letter-spacing: 0.02em;
		text-align: left;
		padding: calc(8px + 0.5vw) calc(20px + 1.25vw);
		gap: calc(8px + 0.5vw);
		border-radius: 90px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		box-sizing: border-box;

		img {
			width: 32px;
			height: 32px;
		}
	}

	@media (min-width: 768px) and (max-width: 1200px) {
		width: 80%;
		margin-right: 0;

		div p {
			&:first-child {
                width: 40%;
            }
			
			&:nth-child(2) {
                width: 60%;
            }
		}
	}

	@media (max-width: 768px) {
		width: 100%;
        margin-right: 0;
		
        a {
            width: 60%;
            padding: calc(4px + 0.5vw) calc(10px + 1.25vw);
        }
	}
	
	@media (max-width: 480px) {
		margin-top: 16px;
		width: 100%;

		div {
			width: 100% !important;
			flex-direction: column;
            gap: 12px;

            p {
                width: 100% !important;
                margin: 0;
            }
		}
		
        a {
            width: 100%;
            padding: calc(4px + 0.5vw) calc(5px + 1.25vw);
			box-sizing: border-box;
        }
    }

    @media (max-width: 320px) {
        div p {
            font-size: 12px;
            line-height: 14.52px;
        }

        a {
            width: 100%;
            font-size: 12px;
            line-height: 14.52px;
			display: flex;
			justify-content: center;
            padding: 10px 28px 10px 28px;
            gap: 10px;

            img {
                width: 16px;
                height: 16px;
            }
        }
    }

	@media (min-width: 1600px) {
		width: 61.9285%;
		gap: 46px;
		margin-right: 66px;
		margin-top: 40px;

		div {
			gap: 67px;

            p {
                font-size: 20px;
                line-height: 24.2px;
				
				&:first-child {
                    width: 46.1361%;
                }
				
				&:nth-child(2) {
                    width: 52.8639%;
                }
            }
		}

		a {
			width: 46.1361%;
			font-size: 28px;
			line-height: 33.89px;
			padding: 16px 40px;
			gap: 16px;
		}
	}
`;
