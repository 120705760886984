import styled from "styled-components";
import questionsChevron from "../../../../Assets/Images/Home/questions-chevron.svg";

export const Section = styled.section`
	width: 100%;
	position: relative;
	left: 0;
	right: 0;
	max-width: 1400px;
	margin: 140px auto 0 auto;

    @media (max-width: 768px) {
        margin-top: 60px;
    }
`;

export const SectionHeading = styled.h1`
	position: relative;
	top: 0;
	display: flex;
	flex-direction: column;

    @media (max-width: 768px) {
		align-items: center;
    }
`;

export const TextRow = styled.span`
	font-family: Inter, sans-serif;
	font-weight: 500;
	font-size: calc(15px + 5.063vw);
	display: block;
	text-transform: uppercase;
	line-height: calc(20px + 5.886vw);
	margin-top: -18px;

	&:nth-of-type(1) {
		margin-left: 12vw;
	}

	&:nth-of-type(3) {
		margin-left: 5.813vw;
	}

	@media (min-width: 768px) and (max-width: 992px) {
		&:nth-of-type(1) {
			margin-left: 60px;
		}

		&:nth-of-type(3) {
			margin-left: 20px;
		}
	}

	@media (max-width: 768px) {
        margin-top: calc(-3px - 0.911vw);
		
		&:nth-of-type(1) {
            margin: calc(-3px - 0.911vw) calc(18px + 5.469vw) 0 0;
		}
		
		&:nth-child(2) {
            margin: calc(-3px - 0.911vw) calc(30px + 9.115vw) 0 0;
		}

		&:nth-of-type(3) {
			margin: calc(-3px - 0.911vw) 0 0 calc(9px + 2.734vw);
		}

        &:nth-of-type(4) {
            margin: calc(-3px - 0.911vw) calc(18px + 5.469vw) 0 0;
        }
	}

    @media (min-width: 1600px) {
        font-size: 96px;
        line-height: 116.18px;

        &:nth-of-type(1) {
            margin-left: 192px;
        }

        &:nth-of-type(3) {
            margin-left: 93px;
        }
    }
`;

export const QuestionsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: calc(5px + 0.313vw);
	margin-top: calc(20px + 7.5vw);

	@media (max-width: 1200px) {
		align-items: center;
	}

	@media (min-width: 1600px) {
		gap: 16px;
		margin-top: 140px;
	}
`;

export const QuestionContainer = styled.details`
	width: 58.31%;
	padding: calc(10px + 0.875vw) calc(8px + 0.75vw);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 30px;
	background: #fafafa;
	font-family: Inter, sans-serif;
	font-size: calc(8px + 1vw);
	font-weight: 400;
	line-height: calc(8px + 1.316vw);
	letter-spacing: 0.02em;
	text-align: left;
	cursor: pointer;
	transition: background-color 0.3s ease;
	box-sizing: border-box;

	&[open] > summary:after {
		transform: rotate(180deg);
	}

	@media (min-width: 992px) and (max-width: 1200px) {
		width: 80%;
		border-radius: 20px;
	}

	@media (min-width: 768px) and (max-width: 992px) {
		width: 90%;
		border-radius: 15px;
	}

    @media (max-width: 768px) {
        width: 100%;
		border-radius: 10px;
    }

	@media (min-width: 1600px) {
		padding: 24px 20px 24px 20px;
		font-size: 24px;
		line-height: 29.05px;
	}
`;

export const QuestionTitle = styled.summary`
	font-family: Inter, sans-serif;
	font-size: calc(10px + 1.125vw);
	font-weight: 500;
	line-height: calc(10px + 1.493vw);
	letter-spacing: 0.02em;
	text-align: left;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	list-style-type: none;
	cursor: pointer;
	
	&::-webkit-details-marker {
		display: none;
	}

	&:after {
		content: url("${questionsChevron}");
		transition: transform 0.2s ease;
		width: 26px;
		height: 26px;
	}

	@media (min-width: 1600px) {
		font-size: 28px;
		line-height: 33.89px;
	}
`;
