import styled, { keyframes } from "styled-components";
import { SwiperSlide } from "swiper/react";

export const Section = styled.section`
	flex: 1;
	min-height: 288px;
	width: calc(100% + 200px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: calc(20px + 6.25vw);
	box-sizing: border-box;
    margin: 187px auto 0 -100px;
    position: relative;
    left: 0;
    right: 0;
    bottom: env(safe-area-inset-bottom);
    top: 0;
	
	@media (max-width: 768px) {
        width: calc(100% + 32px);
		margin: 88px auto 0 -16px;
		gap: 28px;
	}
	
	@media (min-width: 1600px) {
        gap: 120px;
	}
`;

const RevealText = keyframes`
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
`

export const SectionHeading = styled.h1`
    font-size: calc(24px + 4.625vw);
	font-weight: 500;
    line-height: calc(32px + 5.25vw);
	text-align: left;
	display: flex;
	flex-direction: column;
	text-transform: uppercase;
	white-space: nowrap;
	margin: 0 auto 120px;
	width: 100%;
	max-width: 1400px;
    align-items: center;

    & > div {
        overflow: hidden;

        &:first-child {
			margin-right: calc(40px + 15vw);

            span {
                animation-delay: 0s;
            }
        }

        &:nth-child(2) {
            margin-top: calc(-10px - 1.25vw);

            span {
                animation-delay: 0.5s;
            }
        }
    }
	
	@media (min-width: 1200px) and (max-width: 1400px) {
		margin: 0 auto 100px;
	}

	@media (min-width: 1100px) and (max-width: 1200px) {
		margin: 0 auto 80px;
	}

	@media (min-width: 992px) and (max-width: 1100px) {
		margin: 0 auto 60px;
	}
	
	@media (min-width: 480px) and (max-width: 992px) {
        margin: 0;
	}

    @media (max-width: 768px) {
        align-items: center;

        & > div {
            &:first-child {
                margin: 0 0 0 calc(12px + 10.026vw);
				
                span {
                    animation-delay: 0s;
                }
            }

            &:nth-child(2) {
                margin: calc(-3px - 0.911vw) 50px 0 0;

                span {
                    animation-delay: 0.5s;
                }
            }

            &:nth-child(3) {
                margin: calc(-3px - 0.911vw) 0 0 calc(22.2px + 6.745vw);

                span {
                    animation-delay: 1s;
                }
            }
        }
    }

    @media (max-width: 480px) {
        font-size: calc(18px + 4.625vw);
        line-height: calc(22px + 5.25vw);
        margin: 0;
    }
	
	@media (min-width: 1600px) {
        font-size: 96px;
        line-height: 116px;

        & > div {
            &:first-child {
                margin-right: 280px;
            }
            &:nth-child(2) {
                margin-top: -30px;
            }
        }
	}
`;

export const TextRow = styled.span`
	width: min-content;
	display: block;
    transform: translate(0, 100%);
    animation: ${RevealText} 1.5s cubic-bezier(0.77, 0, 0.175, 1) 0.5s;
    animation-fill-mode: forwards !important;
`;

export const CustomSwiperSlide = styled(SwiperSlide)`
    max-width: ${(props) => (props.type === "small" ? "680px" : "934px")};

    @media (min-width: 1400px) and (max-width: 1600px) {
        max-width: ${(props) => (props.type === "small" ? "544px" : "747.2px")};
    }
    @media (min-width: 1200px) and (max-width: 1400px) {
        max-width: ${(props) => (props.type === "small" ? "408px" : "560.4px")};
    }
    @media (min-width: 480px) and (max-width: 1200px) {
        max-width: ${(props) => (props.type === "small" ? "272px" : "373.6px")};
    }
    @media (max-width: 480px) {
        max-width: ${(props) => (props.type === "small" ? "204px" : "280.2px")};
    }
`;

export const SliderImage = styled.img`
	width: 100%;
	display: block;
	height: auto;
	box-sizing: border-box;
`;
